import './Projects.css'
import {useCallback, useContext, useRef} from "react";
import {ReducerContext, SET_PROJECT_DELTA_DURATION, SET_PROJECTS, SET_SEARCH,} from "../reducer";
import authStorage from "../auth_storage";
import {getProjects, toHMS,} from "../timeTracking";
import {useInterval} from "../hooks";
import Project from "./Project";

function Projects({
                      isOwnProjects,
                      isSevenDays,
                      skip,
                      setSkip,
                      take,
                      onClickSubproject,
                  }) {
    const {state, dispatch} = useContext(ReducerContext)
    const timeout = useRef()

    const prepareProjects = projects => {
        return projects.map(project => {
            const duration = project.duration + (state.projectsDeltaDurations[project.id] || 0)
            const {hours, minutes, seconds} = toHMS(duration)
            return {
                ...project,
                hours,
                minutes,
                seconds,
            }
        })
    }

    const projectList = prepareProjects(state.projects)
    const recentProjects = prepareProjects(state.recentProjects)

    const loadNextProjects = async () => {
        const payload = await getProjects(authStorage.get(), isOwnProjects, isSevenDays, skip, take, state.search)
        setSkip(s => s + payload.nextTake)
        dispatch({type: SET_PROJECTS, payload: [...state.projects, ...payload.list]})
    }

    const searchCallback = useCallback(async (e) => {
        if (timeout.current) {
            clearTimeout(timeout.current)
        }
        dispatch({type: SET_SEARCH, payload: e.target.value})
        timeout.current = setTimeout(async () => {
            setSkip(0)
            const payload = await getProjects(authStorage.get(), isOwnProjects, isSevenDays, 0, take, e.target.value)
            setSkip(payload.nextTake)
            dispatch({type: SET_PROJECTS, payload: payload.list})
        }, 500)
    }, [dispatch, isOwnProjects, isSevenDays, setSkip, take])

    useInterval(() => {
        if (state.status?.session?.Subproject?.Project) {
            const now = new Date()
            now.setMilliseconds(0)
            const session = state.status.session
            const duration = Math.floor((now.getTime() - new Date(session.startTimestamp).getTime()) / 1000)
            dispatch({type: SET_PROJECT_DELTA_DURATION, payload: {id: session.Subproject.Project.id, duration}})
        }
    }, 100)

    const now = new Date().getTime()

    let isDottedLineDrown = false

    const projectRow = isPastProjectsLine => p => {
        if (isPastProjectsLine && !isDottedLineDrown && (new Date(p.Project.usagePeriodStart).getTime() < now)) {
            isDottedLineDrown = true
            return <>
                <div className="old-projects-split" />
                <Project onClickSubproject={onClickSubproject} p={p}/>
            </>
        } else {
            return <Project onClickSubproject={onClickSubproject} p={p}/>
        }
    }

    return (
        <>
            <div className="projects">
                {recentProjects.length ?
                    <>
                        <div className="row">
                            <div className="col-12 id d-flex justify-content-center">
                                <h5 className="text-center pb-3 pt-0 text-muted">Recent projects</h5></div>
                        </div>
                        <div className="row g-0 thead border-bottom-2">
                            <div className="col-2 col-lg-1 text-center">
                                ID
                            </div>
                            <div className="col-2 col-lg-3">
                                Kunde
                            </div>
                            <div className="col-4 col-lg-5">
                                <span className="text-truncate w-100 d-block"
                                      title="Projekt">Projekt/Teilprojekt</span>
                            </div>
                            <div className="col-2 col-lg-2">
                                <span className="text-truncate w-100 d-block" title="Eventdate">Eventdate</span>
                            </div>
                            <div className="col-2 col-lg-1 text-center">
                                Time
                            </div>
                        </div>
                        <div className="projects-rows">
                            {recentProjects.map(projectRow(false))}
                        </div>

                        <div className="row">
                            <div className="col-12 id d-flex justify-content-center">
                                <h5 className="text-center py-3 mt-2 text-muted mb-0">Projects</h5></div>
                        </div>
                    </>
                    : null}
                <div className="row g-0 thead border-bottom-2">
                    <div className="col-12 px-2">
                        <input type="search" className="form-control search-input mb-4" placeholder="SUCHEN..."
                               onChange={searchCallback} value={state.search}/>
                    </div>
                </div>
                <div className="row g-0 thead border-bottom border-2 d-md-none">
                    <div className="col-2 text-center">
                        ID
                    </div>
                    <div className="col-2">
                        Kunde
                    </div>
                    <div className="col-6">
                            <span className="text-truncate w-100 d-block"
                                  title="Projekt">Projekt/Eventdate</span>
                    </div>
                    <div className="col-2 text-center">
                        Time
                    </div>
                </div>
                <div className="row g-0 thead border-bottom border-2 d-none d-md-flex">
                    <div className="col-2 col-lg-1 text-center">
                        ID
                    </div>
                    <div className="col-2 col-lg-3">
                        Kunde
                    </div>
                    <div className="col-4 col-lg-5">
                                <span className="text-truncate w-100 d-block"
                                      title="Projekt">Projekt</span>
                    </div>
                    <div className="col-2 col-lg-2">
                        <span className="text-truncate w-100 d-block" title="Eventdate">Eventdate</span>
                    </div>
                    <div className="col-2 col-lg-1 text-center">
                        Time
                    </div>
                </div>
                <div className="projects-rows">
                    {projectList.map(projectRow(true))}
                </div>
            </div>
            {projectList.length > 1 &&
                <button className="btn btn-text d-block my-2 my-lg-3 m-auto loadMore" onClick={loadNextProjects}>Mehr
                    laden
                </button>
            }
        </>
    )
}

export default Projects