import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import {ReducerProvider} from "./reducer"
import {BrowserRouter,} from "react-router-dom"
import * as Sentry from "@sentry/react";
import "./index.css"

Sentry.init({
    dsn: "https://cdbe888b2bd87aee73371bd30a49d22b@o4508000803028992.ingest.de.sentry.io/4508006903709776",
    integrations: [
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    enabled: process.env.NODE_ENV === 'production',
    // Session Replay
    replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
    <React.StrictMode>
        <ReducerProvider>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </ReducerProvider>
    </React.StrictMode>
)