import {useContext} from "react";
import {ReducerContext} from "../reducer";

function SubprojectRow({subproject, onClickSubproject}) {
    const {state,} = useContext(ReducerContext)
    const isHighLighted = state.status?.session?.Subproject?.id === subproject.id;
    return (
        <div key={subproject.id} onClick={() => onClickSubproject(subproject.id)}
             className={"row table-row subproject-row g-0" + (isHighLighted ? " selected" : "")}>
            <div className="col-12 text-center projectName">
                <span className="text-truncate w-100" title={subproject.name}>{subproject.name}</span>
            </div>
        </div>
    )
}

export default SubprojectRow