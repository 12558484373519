import SubprojectRow from "./SubprojectRow";
import {useContext} from "react";
import {ReducerContext, SET_SELECTED_PROJECT_ID} from "../reducer";

function Project({onClickSubproject, p}) {
    const {state, dispatch} = useContext(ReducerContext)
    const isActive = state.status?.session?.Subproject?.projectId === p.id
    const isSelected = state.selectedProjectId === p.id
    const isMultiSubprojects = p.Project.Subprojects.length > 1
    let isExpanded = isMultiSubprojects && (isActive || isSelected)
    const onClickProject = () => {
        if (!isMultiSubprojects) {
            onClickSubproject(p.Project.Subprojects[0].id)
        } else {
            if (isActive) {
                return
            }
            if (isSelected) {
                dispatch({type: SET_SELECTED_PROJECT_ID, payload: null})
            } else {
                dispatch({type: SET_SELECTED_PROJECT_ID, payload: p.id})
            }
        }
    }

    let chevron = null
    if (isMultiSubprojects) {
        chevron = isExpanded ? <i className="bi bi-chevron-up"></i> : <i className="bi bi-chevron-down"></i>
    }

    return (
        <div className={"project-row" + (isActive ? " selected" : "") + (isExpanded ? " expanded" : "")}
             key={p.id}>
            <div onClick={onClickProject}
                 className={"row table-row inner-project-row g-0 d-none d-md-flex"}>
                <div className="col-2 col-lg-1 id d-flex justify-content-center"><span>{p.number}</span></div>
                <div className="col-2 col-lg-3 customer"><span
                    className="text-truncate w-100 pe-1">{p.customer || '-'}</span></div>
                <div className="col-4 col-lg-5 projectName">
                    <span className="text-truncate w-100" title={p.name}>{p.name} {chevron}</span>
                </div>
                <div className="col-2 col-lg-2">
                        <span className="text-truncate w-100"
                              title={new Date(p.Project.usagePeriodStart).toLocaleDateString()}>{new Date(p.Project.usagePeriodStart).toLocaleDateString()}
                </span>
                </div>
                <div className="col-2 col-lg-1 time-cell d-flex justify-content-center">
                    <span>{p.hours < 10 ? "0" : ""}{p.hours}:{p.minutes < 10 ? "0" : ""}{p.minutes}:{p.seconds < 10 ? "0" : ""}{p.seconds}</span>
                </div>
            </div>
            <div onClick={onClickProject}
                 className={"row table-row inner-project-row g-0 d-md-none"}>
                <div className="col-2 id d-flex justify-content-center"><span>{p.number}</span></div>
                <div className="col-2 customer"><span
                    className="text-truncate w-100 pe-1">{p.customer || '-'}</span></div>
                <div className="col-6 projectName justify-content-center flex-column">
                    <div className="hstack">
                        <span className="text-truncate w-100" title={p.name}>{p.name}</span>
                        <span>{chevron}</span>
                    </div>
                    <span className="text-truncate w-100"
                          title={new Date(p.Project.usagePeriodStart).toLocaleDateString()}>{new Date(p.Project.usagePeriodStart).toLocaleDateString()}</span>
                </div>
                <div className="col-2 time-cell d-flex justify-content-center">
                    <span>{p.hours < 10 ? "0" : ""}{p.hours}:{p.minutes < 10 ? "0" : ""}{p.minutes}:{p.seconds < 10 ? "0" : ""}{p.seconds}</span>
                </div>
            </div>
            <div>
                {isExpanded && p.Project.Subprojects.map(s => <SubprojectRow onClickSubproject={onClickSubproject}
                                                                             subproject={s}/>)}
            </div>
        </div>
    )
}

export default Project